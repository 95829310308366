import $ from 'jquery';
import mask from 'jquery-mask-plugin';
import './_foundation';
// import './_restrictTags';
import './pages/_home';
// import './_header';
// import './_footer';
// import './_interactive';
// import './_product-detail';
// import './_product-listing';
// import './_datapage';
// import './_ingredients';
// import './_vue-apps';
// import './_industries';
// import './_validations';