import $ from 'jquery';

import {
    Core as Foundation,
    // Abide,
    Accordion,
    AccordionMenu,
    Drilldown,
    Dropdown,
    DropdownMenu,
    Equalizer,
    Interchange,
    // Magellan,
    // OffCanvas,
    // Orbit,
    ResponsiveMenu,
    ResponsiveToggle,
    // Reveal,
    // Slider,
    // SmoothScroll,
    Sticky,
    // Tabs,
    // Toggler,
    // Tooltip,
    ResponsiveAccordionTabs,
} from 'foundation-sites';

Foundation.addToJquery($);

$(document).ready(function(){
    $(document).foundation();
});
