
  $(()=>{
      var createSlick = ()=>{
        let slider = $(".slider");
        slider.not('.slick-initialized').slick({
            // normal options...
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            // centerMode: true,
            variableWidth: true,
            prevArrow:'<a class="prevarrow"></a>',
            nextArrow:'<a class="nextarrow"></a>',
            // the magic
            responsive: [{          
                breakpoint: 1920,
                settings: {
                  slidesToShow: 3,
                  infinite: false
                }          
              }, {
          
                breakpoint: 1200,
                settings: "unslick" // destroys slick
          
              }]
        });


      
        let sliderHome = $(".home-learnmore-slide-row");
        sliderHome.not('.slick-initialized').slick({
          // normal options...
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          variableWidth: true,
          dots: true,
          centerMode: true,
          prevArrow:'<a class="prevarrow-learnmore"></a>',
          nextArrow:'<a class="nextarrow-learnmore"></a>',
          // the magic
          responsive: [{        
            breakpoint: 1024,
            settings: {
              // centerMode: false,
              adaptiveHeight: false,
              variableWidth: false,
            }      
          }]           
      });

      let sliderCategory = $(".category-home-carousel");
      sliderCategory.not('.slick-initialized').slick({
          // normal options...
          infinite: true,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: true,
          variableWidth: true,
          centerMode: true,
          prevArrow:'<a class="prevarrow-category"></a>',
          nextArrow:'<a class="nextarrow-category"></a>',
          // the magic
          responsive: [{        
            breakpoint: 1920,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }      
          }]          
      });

      let sliderCategoryList = $(".listing-categories");
      sliderCategoryList.not('.slick-initialized').slick({
          // normal options...
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: true,
          variableWidth: true,
          centerMode: true,
          mobileFirst: true,
          prevArrow:'<a class="prevarrow-category"></a>',
          nextArrow:'<a class="nextarrow-category"></a>',
          // the magic
          responsive: [{          
            breakpoint: 1439,
            settings: "unslick"                      
          }]         
      });

      let prodetailMedium = $("#product-detail-gallery-medium");
      prodetailMedium.not('.slick-initialized').slick({
          // normal options...
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: true,
          variableWidth: true,
          centerMode: true,
          mobileFirst: true,
          prevArrow: false,
          nextArrow: false,
          lazyLoad: 'ondemand',
          asNavFor: '#product-detail-gallery-thumbs',
          // prevArrow:'<a class="prevarrow-category"></a>',
          // nextArrow:'<a class="nextarrow-category"></a>',
          // the magic
          // responsive: [{          
          //   breakpoint: 1439,
          //   settings: "unslick"                      
          // }]         
      });

      let prodetailThumbs = $("#product-detail-gallery-thumbs");

      prodetailThumbs.not('.slick-initialized').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        lazyLoad: 'ondemand',
        // centerPadding: '0px',
        // adaptiveHeight: true,
        // variableWidth: true,
        asNavFor: '#product-detail-gallery-medium',
        dots: false,
        centerMode: false,
        draggable: true,
        speed:200,
        mobileFirst: true,
        focusOnSelect: true,
        prevArrow:'<a class="prevarrow-detail"></a>',
        nextArrow:'<a class="nextarrow-detail"></a>'
      });


    }

    createSlick();
    $(window).on( 'resize orientationchange', createSlick );

    // $("#product-detail-gallery-medium").on('click', function(){
    //   // e.event
    //   $('#fancybox-gallery a').click();
    // })
    var src = $('#fancybox-img').attr('src'); // Looks for the *fancyboxed* image
    var idx = $('a[href="'+src+'"]').index();
    // alert(idx);
    $('[data-fancybox="images"]').fancybox({
      beforeShow: function(){
        // var currentIndex = current.index;
        // alert(currentIndex);
        // $("#product-detail-gallery-medium").slick('unslick');
        // $("#product-detail-gallery-thumbs").slick('unslick');
        // alert(idx);
      },
      afterClose:function(){        
        location.reload();        
      },
      afterShow: function(instance, current) {
        // var currentIndex = current.index;
        // alert(currentIndex);
        // if (current.index  ===  instance.group.length - 1) {
        //   alert('I am the last item of the gallery');
        // }
      },
      
    });



    
    $(".mobileMenu").on('click', function() {
      $(this).toggleClass("active");
      $('.header-navigation--mobile').toggleClass("open");
      $("body").toggleClass("overflowhidden");
      $(".header-mobile-search-icon").toggleClass("hiddenvisiblity");
    });

    $(".header-mobile-search-icon, .search-button-submit, .overlay-search").on('click', function() {      
      $('.header-search, .overlay-search').toggleClass("active");
      $("body").toggleClass("overflowhidden");
      $(".header-search-form #search").focus();
    });

    /******************************* */
    $.widget( "custom.catcomplete", $.ui.autocomplete, {
      _create: function() {
        this._super();
        this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
      },
      _renderMenu: function( ul, items ) {
        var that = this,
          currentCategory = "";
        $.each( items, function( index, item ) {
          var li;
          if ( item.category != currentCategory ) {
            ul.append( "<li class='ui-autocomplete-category'>" + item.category + "</li>" );
            currentCategory = item.category;
          }
          li = that._renderItemData( ul, item );
          if ( item.category ) {
            li.attr( "aria-label", item.category + " : " + item.label );
          }
        });
      }
    });
    var data = [
      { label: "anders", category: "" },
      { label: "andreas", category: "" },
      { label: "antal", category: "" },
      { label: "annhhx10", category: "Products" },
      { label: "annk K12", category: "Products" },
      { label: "annttop C13", category: "Products" },
      { label: "anders andersson", category: "People" },
      { label: "andreas andersson", category: "People" },
      { label: "andreas johnson", category: "People" }
    ];
 
    $( "#search, #searchHero" ).catcomplete({
      delay: 0,
      source: data
    });
    $(window).on( 'resize orientationchange', function(){
      $('.ui-autocomplete').css('width', $('#search').width());
    });
    
    
    /*listing*/
    $(".filterButton").on('click', function(){
      $('.overlay-filter').addClass("active");
      $(".listing-filters").addClass("active");
    });

    $(".listing-filters-close, .overlay-filter").on('click', function(){
      $(".listing-filters").removeClass("active");
      $('.overlay-filter').removeClass("active");
    });

    $(".listing-filters-group h5").on('click', function(){
      $(this).parents(".listing-filters-group").toggleClass("active");
    });

    $(".listing-filters-list ul li a").on('click', function(){
      $(this).toggleClass('checked');
    });
    

    $(window).on( 'resize orientationchange', function(){
      $(".listing-filters").removeClass("active");
      $('.overlay-filter').removeClass("active");
    });


    function viewMore(){
      $('.listing-filters-list').each(function(){
        $this = $(this);
        if ($this.find('ul > li').length > 3) {
          $this.append('<div><a class="filter-view-more">View more</a></div>');
        }
        $this.find('ul > li').slice(0,3).addClass('shown');
        $this.find('ul > li').not('.shown').hide();
      });

      // var $this = $('.listing-filters-list > ul');
      
      
      // If more than 2 Education items, hide the remaining
      // $('.listing-filters-list ul li').slice(0,3).addClass('shown');
      // $('.listing-filters-list ul li').not('.shown').hide();
      $('.listing-filters-list .filter-view-more').on('click',function(){
        $this = $(this);
        $this.parents('.listing-filters-list').find("ul li").not('.shown').toggle(100);
        // $(this).toggleClass('showLess');
        $(this).text($(this).text() == 'View less' ? 'View more' : 'View less');
      });
    }
    viewMore();



 
  

});
